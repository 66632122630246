import React from 'react'
import email from "../../assets/icons8-email-30.png";
import addresss from "../../assets/address.svg";


const Footer = () => {
  return (
    <div>
      
      <div className="footer-wrapper">
        <div className="contact-info">  
        <p className="text-center">Pristine Highprofile</p> 
        <p className="text-center"> <i><img className="pe-1" src={email} /></i> pristinehighprofileservice@gmail.com</p>
        <p className="text-center"> <i><img className="pe-1" src={addresss} /></i> 3 Nelly Ct, Bramton, ON, L6P 1S9</p>
        </div>
        <div className="mt-5">
            <p className="mb-0 text-center">Copyright @ 2024 All in One High Profile Service. All Rights Reserved</p>
        </div>
          </div>
    </div>
  )
}

export default Footer
