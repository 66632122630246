// import React, { useState } from 'react';
// import './service.css';
// import construction from "../../assets/construction.svg";
// import cleaning from "../../assets/cleaning.svg";
// import electricity from "../../assets/electricity.svg";
// import plumbing from "../../assets/plumbing.svg";
// import Modal from '../Modal/Modal.js';

// const Service = () => {
//   const [modalContent, setModalContent] = useState(null);

//   const openModal = (content) => {
//     setModalContent(content);
//   };

//   const closeModal = () => {
//     setModalContent(null);
//   };

//   return (
//     <>
//       <div className="container" id="service">
//         <h2 className="service-title">Services</h2>
//         <div className="row gy-5 gy-lg-0 justify-content-center service-box-container">
//           {/* Construction */}
//           <ServiceBox
//             image={construction}
//             title="Construction"
//             description="Contrary to popular belief, Lorem Ipsum is not simply random text."
//             onOpenModal={() => openModal({ title: "Construction", description: "Dynamic content for Construction service." })}
//           />
//           {/* Cleaning */}
//           <ServiceBox
//             image={cleaning}
//             title="Cleaning"
//             description="Contrary to popular belief, Lorem Ipsum is not simply random text."
//             onOpenModal={() => openModal({ title: "Cleaning", description: "Dynamic content for Cleaning service." })}
//           />
//           {/* Plumbing */}
//           <ServiceBox
//             image={plumbing}
//             title="Plumbing"
//             description="Contrary to popular belief, Lorem Ipsum is not simply random text."
//             onOpenModal={() => openModal({ title: "Plumbing", description: "Dynamic content for Plumbing service." })}
//           />
//           {/* Electricity */}
//           <ServiceBox
//             image={electricity}
//             title="Electricity"
//             description="Contrary to popular belief, Lorem Ipsum is not simply random text."
//             onOpenModal={() => openModal({ title: "Electricity", description: "Dynamic content for Electricity service." })}
//           />
          
//           <ServiceBox
//             image={electricity}
//             title="HVAC"
//             description="Contrary to popular belief, Lorem Ipsum is not simply random text."
//             onOpenModal={() => openModal({ title: "HVAC", description: "Dynamic content for HVAC service." })}
//           />
//         </div>
//         {modalContent && (
//           <Modal isOpen={true} onClose={closeModal}>
//             <h2>{modalContent.title}</h2>
//             <p>{modalContent.description}</p>
//           </Modal>
//         )}
//       </div>
//     </>
//   );
// };

// const ServiceBox = ({ image, title, description, onOpenModal }) => {
//   return (
//     <div className="col-12 col-lg-2 col-md-6">
//       <div className="service-box">
//         <div className="d-flex justify-content-center py-3">
//           <img src={image} className="service-box-image" alt={title} />
//         </div>
//         <h4 className="text-center service-box-text">{title}</h4>
//         <p className="service-desc-text">{description}</p>
//         <div>
//           <button className="btn service-box-btn" onClick={onOpenModal}>View More</button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Service;

import React, { useState } from 'react';
import './service.css';
import construction from "../../assets/construction.svg";
import cleaning from "../../assets/cleaning.svg";
import electricity from "../../assets/electricity.svg";
import plumbing from "../../assets/plumbing.svg";
import Modal from '../Modal/Modal.js';

const Service = () => {
  const [modalContent, setModalContent] = useState(null);

  const openModal = (content) => {
    setModalContent(content);
  };

  const closeModal = () => {
    setModalContent(null);
  };

  return (
    <>
      <div className="container" id="service">
        <h2 className="service-title">Services</h2>
        <div className="row gy-5 gy-lg-0 justify-content-center service-box-container">
          {/* Construction */}
          <ServiceBox
            image={construction}
            title="Construction"
            description="Contrary to popular belief, Lorem Ipsum is not simply random text."
            onOpenModal={() => openModal({ title: "Construction", description: "Dynamic content for Construction service." })}
          />
          {/* Cleaning */}
          <ServiceBox
            image={cleaning}
            title="Cleaning"
            description="Contrary to popular belief, Lorem Ipsum is not simply random text."
            onOpenModal={() => openModal({ title: "Cleaning", description: "Dynamic content for Cleaning service." })}
          />
          {/* Plumbing */}
          <ServiceBox
            image={plumbing}
            title="Plumbing"
            description="Contrary to popular belief, Lorem Ipsum is not simply random text."
            onOpenModal={() => openModal({ title: "Plumbing", description: "Dynamic content for Plumbing service." })}
          />
          {/* Electricity */}
          <ServiceBox
            image={electricity}
            title="Electricity"
            description="Contrary to popular belief, Lorem Ipsum is not simply random text."
            onOpenModal={() => openModal({ title: "Electricity", description: "Dynamic content for Electricity service." })}
          />
          
          <ServiceBox
            image={electricity}
            title="HVAC"
            description="Contrary to popular belief, Lorem Ipsum is not simply random text."
            onOpenModal={() => openModal({ title: "HVAC", description: "Dynamic content for HVAC service." })}
          />
        </div>
        <Modal isOpen={modalContent !== null} onClose={closeModal}>
          {modalContent && (
            <>
              <h2>{modalContent.title}</h2>
              <p>{modalContent.description}</p>
            </>
          )}
        </Modal>
      </div>
    </>
  );
};

const ServiceBox = ({ image, title, description, onOpenModal }) => {
  return (
    <div className="col-12 col-lg-2 col-md-6">
      <div className="service-box">
        <div className="d-flex justify-content-center py-3">
          <img src={image} className="service-box-image" alt={title} />
        </div>
        <h4 className="text-center service-box-text">{title}</h4>
        <p className="service-desc-text">{description}</p>
        <div>
          <button className="btn service-box-btn" onClick={onOpenModal}>View More</button>
        </div>
      </div>
    </div>
  );
};

export default Service;

