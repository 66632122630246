// import React from 'react';
// import "./header.css";
// import { Link } from "react-scroll";
// const Header = () => {
//   return (
//     <div>
//       <nav class="navbar navbar-expand-lg bg-body-tertiary">
//         <div class="container header-main-wrapper">
//           <a class="navbar-brand mr-auto" href="#">Pristine Highprofile</a>
//           <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
//             <span class="navbar-toggler-icon"></span>
//           </button>

//         <div>
//           <div class="collapse navbar-collapse" id="navbarSupportedContent">
//             <ul class="navbar-nav me-auto mb-2 mb-lg-0">
//               <li class="nav-item">
//                 <Link to="home" spy={true} smooth={true} offset={-100} duration={300} class="nav-link " aria-current="page" href="#">Home</Link>
//               </li>
//               <li class="nav-item">

//                 <Link to="service" spy={true} smooth={true} offset={-100} duration={300} class="nav-link" aria-current="page" href="#">Services</Link>
//               </li>
//               <li class="nav-item">

//                 <Link to="contact" spy={true} smooth={true} offset={-100} duration={300} class="nav-link " aria-current="page" href="#">Contact</Link>
//               </li>
//             </ul>
//           </div>

//           </div>
//         </div>
//       </nav>


//     </div>
    
//   )
// }

// export default Header

import React from 'react';
import "./header.css";
import { Link } from "react-scroll";

const Header = () => {
  return (
    <div>
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container header-main-wrapper">
          <a className="navbar-brand mr-auto" href="#">Pristine Highprofile</a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link to="home" spy={true} smooth={true} offset={-100} duration={300} className="nav-link">Home</Link>
              </li>
              <li className="nav-item">
                <Link to="service" spy={true} smooth={true} offset={-100} duration={300} className="nav-link">Services</Link>
              </li>
              <li className="nav-item">
                <Link to="contact" spy={true} smooth={true} offset={-100} duration={300} className="nav-link">Contact</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
