// import React from 'react'
// import "./banner.css";

// import banner from "../../assets/Movers-removebg-preview.png"
// const Banner = () => {
//   return (
//     <>
//         <div className="banner-wrapper container" id="home">
//             <div className="row align-items-center">
//                 <div className="col-12 col-md-6 col-lg-6">
//                       <h1 className="banner-text">Find Trusted Local Pros for Your Next Home Project</h1>
//                       <p className="banner-subtext">From remodeling to repairs, find the perfect match for every project. Explore our network of vetted local experts ready to bring your home visions to life.</p>
                      
//                       <div className="banner-btn">
//                           <button className="btn">Say Hi !</button>
//                       </div>
//                 </div>
                
//                   <div className="col-12 col-md-6 col-lg-6">
//                       <div className="banner-right-img">  
//                         <img src={banner}  />
//                       </div>
//                 </div>
                  
//             </div>
//         </div>
    
//     </>
//   )
// }

// export default Banner


import React from 'react';
import "./banner.css";
import banner from "../../assets/Movers-removebg-preview.png";

const Banner = () => {
  return (
    <div className="banner-wrapper container" id="home">
      <div className="row align-items-center">
        <div className="col-12 col-md-6">
          <h1 className="banner-text">Find Trusted Local Pros for Your Next Home Project</h1>
          <p className="banner-subtext">From remodeling to repairs, find the perfect match for every project. Explore our network of vetted local experts ready to bring your home visions to life.</p>
          <div className="banner-btn">
            <button className="btn">Say Hi !</button>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="banner-right-img">  
            <img src={banner} alt="Banner" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
