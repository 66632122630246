import logo from './logo.svg';
// import './App.css';
import Header from "./component/header/Header";
import Banner from './component/Banner/Banner';
import Service from './component/Service/Service';
import Contact from './component/contact/Contact.js';
import Footer from './component/footer/Footer';
function App() {
  return (
    <div className="App">
      
      <Header />
      <Banner />
      <Service />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
