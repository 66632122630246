// import React,{ useState } from 'react'
// import contactImg from "../../assets/contact-us-phone.jpg";
// import "./contact.css"
// const Contact = () => {
//     const [formData, setFormData] = useState({
//         name: '',
//         email: '',
//         contact: '',
//         subject: '',
//         message: ''
//       });

//       const handleChange = (e) => {
//         setFormData({ ...formData, [e.target.name]: e.target.value });
//       };

//       const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//           const response = await fetch('http://localhost:3001/api/contactus', {
//             method: 'POST',
//             headers: {
//               'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(formData)
//           });
//           const data = await response.json();
//           console.log(data); // Handle the response data as needed
//           // Clear the form after successful submission
//           setFormData({ name: '', email: '', message: '' });
//         } catch (error) {
//           console.error('Error occurred:', error);
//         }
//       };

//   return (
//     <>  
//           <h2 className="service-title" id="contact">Contact Us</h2>
//           <form onSubmit={handleSubmit}>
//           <div className="container mb-5">
//             <div className="row justify-content-center">
//                   <div className="col-lg-7 col-md-10">
//                       <div className="contact-form-wrapper">

//                           <div className="row gy-3">
//                               <div className="col-12 col-md-6">
//                                   <input type="text" className="contact-input" placeholder="Name *"  value={formData.name}  onChange={handleChange} required/>
//                               </div>
//                               <div className="col-12 col-md-6">
//                                   <input type="email" className="contact-input" placeholder="Email *"  value={formData.email} onChange={handleChange} required/>
//                               </div>
//                           </div>

//                           <div className="row mt-3">
//                               <div className="col-12">
//                                   <input type="number" className="contact-input appearance-none" placeholder="Contact *"  value={formData.contact} onChange={handleChange} required />
//                               </div>
//                           </div>

//                           <div className="row mt-3">
//                               <div className="col-12">
//                                   <input type="text" className="contact-input" placeholder="Subject *"  value={formData.subject} onChange={handleChange} required />
//                               </div>
//                           </div>

//                           <div className="row mt-3">
//                               <div className="col-12">
//                                   <textarea type="text" rows="4" className="contact-input" placeholder="Your Message *"  value={formData.message} onChange={handleChange} required/>
//                               </div>
//                           </div>
//                            <div className="row mt-3">
//                               <div className="col-12">
//                                   <div className="contact-btn text-center">
//                                         <button className="btn">Submit</button>
//                                     </div>
//                               </div>
//                             </div>
//                       </div>
//                   </div>
//             </div>
//           </div>
//           </form>

//           {/* footer */}


//     </>
//   )
// }

// export default Contact;


import React, { useState } from 'react';
import { baseUrl } from '../../Urls';
import contactImg from "../../assets/contact-us-phone.jpg";
import "./contact.css";

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        service: '',
        message: ''
    });
    const [isSuccess, setIsSuccess] = useState(false);
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${baseUrl}/api/contactus`, {
                method: 'post',
                mode: 'cors',
                headers: {
                    // 'Access-Control-Allow-Origin': 'http://localhost:3000',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            // const data = await response.json();
            // if(data)
            //     setIsSuccess(true);
            // else
            //     setIsSuccess(false);

            // console.log(data); // Handle the response data as needed
            // // Clear the form after successful submission
            // setFormData({ name: '', email: '', message: '' ,phone: '',service:'' });
            const data = await response.json();
            console.log(data);
            if (response.ok) { // This checks if the status code is in the range of 200-299
                setIsSuccess(true);
                console.log('Success:', data);
                // Clear the form after successful submission
                alert('Information sent successfully');
                setFormData({ name: '', email: '', message: '', phone: '', service: '' });
            } else {
                // Handle different status codes if needed
                alert('something went wrong...');
                console.error(`Error ${response.status}: ${data}`);
                setIsSuccess(false);
            }
        } catch (error) {
            alert('something went wrong...');
            console.error('Error occurred:', error);
            setIsSuccess(false);
        }
    };

    return (
        <>
            <h2 className="service-title" id="contact">Contact Us</h2>
            {isSuccess ? (
                <h2 style={{ textAlign: 'center', color: 'green' }}>Information sent successfully</h2>
            ) : (
                <h2 style={{ textAlign: 'center' }}></h2>
            )}
            <form onSubmit={handleSubmit}>
                <div className="container mb-5">
                    <div className="row justify-content-center">
                        <div className="col-lg-7 col-md-10">
                            <div className="contact-form-wrapper">
                                <div className="row gy-3">
                                    <div className="col-12 col-md-6">
                                        <input type="text" className="contact-input" placeholder="Name *" name="name" value={formData.name} onChange={handleChange} required />
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <input type="email" className="contact-input" placeholder="Email *" name="email" value={formData.email} onChange={handleChange} required />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-12">
                                        <input type="number" className="contact-input appearance-none" placeholder="Contact *" name="phone" value={formData.phone} onChange={handleChange} required />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-12">
                                        <input type="text" className="contact-input" placeholder="Subject *" name="service" value={formData.service} onChange={handleChange} required />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-12">
                                        <textarea type="text" rows="4" className="contact-input" placeholder="Your Message *" name="message" value={formData.message} onChange={handleChange} required />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <div className="contact-btn text-center">
                                            <button className="btn">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            {/* footer */}


        </>
    )
}

export default Contact;

