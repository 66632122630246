// // Modal.js
// import React from 'react';
// import './modal.css';

// const Modal = ({ isOpen, onClose, children }) => {
//     if (!isOpen) return null;

//     return (
//         <div className="modal">
//             <div className="modal-content">
//                 <span className="close" onClick={onClose}>&times;</span>
//                 {children}
//             </div>
//         </div>
//     );
// };

// export default Modal;

import React, { useState } from 'react';
import './modal.css';

const Modal = ({ isOpen, onClose, children }) => {
  // Apply 'active' class based on the 'isOpen' state
  const modalClassName = isOpen ? 'modal active' : 'modal';

  return (
    <div className={modalClassName}>
      <div className="modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        {children}
      </div>
    </div>
  );
};

export default Modal;

